<template>
  <a href="https://rupetitor.ru">
    <img
      :src="require('@/assets/images/logo/logo.png')"
      :alt="appName"
    >
  </a>
</template>

<script>
export default {
  computed: {
    appName() {
      return process.env.VUE_APP_APP_NAME
    },
  },
}
</script>
